/*
 * @Author: 孙林 1164700321@qq.com
 * @Date: 2024-08-09 10:36:36
 * @LastEditors: 孙林
 * @LastEditTime: 2024-08-15 13:55:10
 * @Description: 
 */
import DGuideModal from "@src/components/DGuideModal";
import Logo from "@src/components/Logo";
import { checkOpen } from "@src/utils/checkOpen";
import { handleDownload, ossDownload } from "@src/utils/download";
import { isWechat, isAndroid } from "@src/utils/env";
import { useEffect, useState } from "react";

import s from './index.module.scss';

export default function Screen1() {

    return <div className={s.bodyThree} id='bodyThree'>
        <div className={s.title} id='threeTitle'>产品介绍</div>
        <div className={s.subTitle} id='threeSubTitle'>视频匹配加速对接，高效连接求职者与经纪人</div>
        <div className={s.contentWrapper} id='threeContentWrapper'>
            <div className={s.leftWrapper}>
                <div className={s.leftContent}>
                    <div className={s.leftContentImg}>
                        <img src="https://appu-common.oss-cn-hangzhou.aliyuncs.com/home/screen3_tag.webp" alt="" />
                        <div>视频开聊 + AI智能简历</div>
                    </div>
                    <div className={s.contentDetail}>在线匹配求职，直接与人事/HR开聊；</div>
                    <div className={s.contentDetail}>享受打车般的求职体验，每一次沟通都在优化简历</div>
                </div>
                <img className={s.leftImg} src="https://appu-common.oss-cn-hangzhou.aliyuncs.com/home/screen3_left1.webp" alt="" />
            </div>
            <div className={s.rightWrapper}>
                <img className={s.rightImg} src="https://appu-common.oss-cn-hangzhou.aliyuncs.com/home/screen3_right.webp" alt="" />
                <div className={s.rightContent}>
                    <div className={s.rightContentImg}>
                        <img src="https://appu-common.oss-cn-hangzhou.aliyuncs.com/home/screen3_tag.webp" alt="" />
                        <div>招聘更便捷  效果更显著</div>
                    </div>
                    <div className={s.contentDetail}>快速对接求职者，面对面引导介绍；</div>
                    <div className={s.contentDetail}>提高效率，降低招人成本</div>
                </div>
            </div>
        </div>
    </div>
}
