/*
 * @Author: 孙林 1164700321@qq.com
 * @Date: 2024-08-08 14:44:24
 * @LastEditors: 孙林
 * @LastEditTime: 2024-08-19 11:50:56
 * @Description: 
 */
import * as Sentry from "@sentry/react";
import { Route, Routes } from 'react-router-dom';
import HomePage from '@pages/home';
import AboutPage from '@pages/about';
import VConsole from './utils/vconsole';
import UniLoading, { LoadingProvider } from "./components/UniLoading";

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export default Sentry.withProfiler(function App() {
    return <Sentry.ErrorBoundary>
        <LoadingProvider>
            <UniLoading />
            <SentryRoutes>
                <Route path="/" element={<HomePage />} />
                <Route path="/about" element={<AboutPage />} />
            </SentryRoutes>
        </LoadingProvider>
        <VConsole />
    </Sentry.ErrorBoundary>
});
