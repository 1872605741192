/*
 * @Author: 孙林 1164700321@qq.com
 * @Date: 2024-08-08 14:44:24
 * @LastEditors: 孙林
 * @LastEditTime: 2024-08-28 16:41:09
 * @Description: 
 */
import s from './index.module.scss';
import { useLoading } from '@src/components/UniLoading';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Screen1 from './Screen1';
import Screen2 from './Screen2';
import Screen3 from './Screen3';
import Screen4 from './Screen4';
import Screen5 from './Screen5';
import About from './About';
import Footer from './Footer';

import ScreenOne from './ScreenOne';
import ScreenTwo from './ScreenTwo';
import ScreenThree from './ScreenThree';
import ScreenFour from './ScreenFour';
import NewFooter from './newFooter';


import Phone from './phone';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default (function HomePage() {
    const { switchIsShow } = useLoading();
    const [isDesktop, setIsDesktop] = useState(0);

    function detectDesktop() {
        if (document.documentElement.clientWidth > 768) {
            setIsDesktop(1);
        } else {
            setIsDesktop(2);
        }
    }

    const location = useLocation();

    useEffect(() => {
        detectDesktop();
        window.scrollTo(0, 0);

    }, [location]);  // 每当 location 变化时触发

    useEffect(() => {
        switchIsShow(false);
    });

    useEffect(() => {
        if (isDesktop === 1) {
            setTimeout(() => {
                const target = document.querySelector(`#contentWrappper`);

                const twoTitle = document.querySelector(`#twoTitle`);
                const twoSubtitle = document.querySelector(`#twoSubtitle`);
                const threeTitle = document.querySelector(`#threeTitle`);
                const threeSubTitle = document.querySelector(`#threeSubTitle`);
                const threeContentWrapper = document.querySelector(`#threeContentWrapper`);
                const fourTitle = document.querySelector(`#fourTitle`);
                const fourSubTitle = document.querySelector(`#fourSubTitle`);
                const fourImg = document.querySelector(`#fourImg`);
                const doms = [target, twoTitle, twoSubtitle, threeTitle, threeSubTitle, threeContentWrapper, fourTitle, fourSubTitle, fourImg];
                doms.forEach((item) => {
                    gsap.fromTo(
                        item,
                        {
                            opacity: 0,
                            y: 50
                        },
                        {
                            opacity: 1,
                            duration: 1,   // 动画持续时间
                            y: 0,
                            ease: "power1.inOut",
                            scrollTrigger: {
                                trigger: item,
                                scrub: false,            // 定义动画是否应该与滚动同步。设置为 true 时，动画将随着滚动同步播放。false（默认），随着元素出现在视窗内，直接触发动画
                                markers: false,    // 是否显示开始和结束的标记线，用于调试。
                                toggleActions: "play none none reset"
                            },
                        }
                    );
                })
            }, 200);
        }
    }, [isDesktop])

    useEffect(() => {
        document.addEventListener("DOMContentLoaded", detectDesktop, false);
        document.addEventListener("resize", detectDesktop, false);
        window.addEventListener("load", detectDesktop, false);
        return () => {
            document.removeEventListener("DOMContentLoaded", detectDesktop);
            document.removeEventListener("resize", detectDesktop);
            window.removeEventListener("load", detectDesktop);
        }
    }, [location]);

    return <div className={s.room}>
        {
            isDesktop === 1 &&
            <>
                <ScreenOne />
                <ScreenTwo />
                <ScreenThree />
                <ScreenFour />
                <About />
                <NewFooter />
                {/* <Screen1 />
                <Screen2 />
                <Screen3 />
                <Screen4 />
                <Screen5 />
                <Footer /> */}
            </> ||
            isDesktop === 2 &&
            <Phone /> ||
            <p>啥都没有</p>
        }

    </div>
})
