import s from "./index.module.scss";

export default function DGuideModal({ isShow, onClose, }) {
    if (!isShow) {
        return null;
    }
    return <div className={s.container}>
        <div className={s.body}>
            <svg
                className={s.icon}
                viewBox="0 0 1024 1024"
                xmlns="http://www.w3.org/2000/svg"
                width={64}
                height={64}
            >
                <path
                    d="M475 948V214L199 491 147 439l312-312L512 75l51 51L876 439l-51 51L548 214V948h-1"
                    fill='#ffffff'
                />
            </svg>
            <p>1. 点击右上角“三个点”按钮</p>
            <p>2. 选择“在浏览器中打开”</p>
            <div className={s.close} onClick={onClose}>知道了</div>
        </div>
    </div >
};
