import { isIphone, isIpad, isWechat } from "./env";
import { appstoreUrl, ApuHost, ApuPkgName, ApuScheme } from "./openApp";

const yingyongbaoUrl = 'https://a.app.qq.com/o/simple.jsp?pkgname=com.apu.tech.apu_app';
const ossDownloadUrl = 'https://apu-apk.oss-accelerate.aliyuncs.com/apujiyu-app-64.apk';

function buildQs(param?: Record<string, any>) {
    const qs =
        typeof param !== 'undefined'
            ? Object.keys(param)
                .map((key) => `${key}=${param[key]}`)
                .join('&')
            : '';

    return qs ? `?${qs}` : '';
}

export function generateYingYongBao() {
    const url = buildScheme('');
    // 支持 AppLink
    return `${yingyongbaoUrl}&android_schema=${encodeURIComponent(url)}`;
}

export function buildScheme(path, param?) {
    const qs = buildQs(param);

    return `${ApuScheme}://${ApuHost}/${path}${qs}`;
}

let iframe: HTMLIFrameElement;

const libs = {
    xiaomi: {
        reg: /\(.*Android.*(MI|Mi|Redmi).*\)/,
        scheme: `mimarket://details?id=${ApuPkgName}`
    },
    samsung: {
        reg: /\(.*Android.*(SAMSUNG|SM-).*\)/,
        scheme: `samsungapps://ProductDetail/${ApuPkgName}`
    },
    huawei: {
        reg: /\(.*Android.*(HUAWEI|HONOR).*\)/i,
        scheme: `appmarket://details?id=${ApuPkgName}`
    },
    oppo: {
        reg: /\(.*Android.*OPPO.*\)/,
        scheme: `oppomarket://details?packagename=${ApuPkgName}`
    },
    vivo: {
        reg: /\(.*Android.*(vivo|VIVO).*\)/,
        scheme: `vivomarket://details?id=${ApuPkgName}`
    }
}

function evokeByIFrame(uri: string) {
    if (!iframe) {
        iframe = document.createElement('iframe');
        iframe.style.cssText = 'display:none;border:0;width:0;height:0;';
        document.body.append(iframe);
    }

    iframe.src = uri;
}

function evokeByLocation(uri: string) {
    window.location.href = uri;
}

export const ossDownload = () => {
    // 浏览器内安卓直接出下载文件
    const src = ossDownloadUrl;
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = "javascript: '<script>location.href=\"" + src + "\"<\/script>'";
    document.getElementsByTagName('body')[0].appendChild(iframe);
}

export const handleDownload = () => {
    if (isIphone || isIpad) {
        window.location.href = appstoreUrl;
    } else {
        if (isWechat) {
            if (yingyongbaoUrl) {
                // 应用宝
                evokeByLocation(generateYingYongBao());
            } else {
                // 微信内直接返回，checkOpen会出蒙层，引导在浏览器打开
                return;
            }
        } else {
            Object.keys(libs).forEach(key => {
                const obj = libs[key];
                const { scheme } = obj;
                evokeByIFrame(scheme);
            });
        }
    }
}
