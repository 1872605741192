/*
 * @Author: 孙林 1164700321@qq.com
 * @Date: 2024-08-08 14:44:24
 * @LastEditors: 孙林
 * @LastEditTime: 2024-08-28 16:34:22
 * @Description: 
 */
import s from './index.module.scss';
import { useLoading } from '@src/components/UniLoading';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/autoplay';
// import 'swiper/css/bundle'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';

import NewFooter from '../home/newFooter';


// import { Carousel } from 'antd-mobile';

export default (function AboutPage() {
    const navigate = useNavigate();
    const { switchIsShow } = useLoading();
    const [bannerList, setBannerList] = useState([
        'https://appu-common.oss-cn-hangzhou.aliyuncs.com/home/swiper1.webp',
        'https://appu-common.oss-cn-hangzhou.aliyuncs.com/home/swiper5.webp',
        'https://appu-common.oss-cn-hangzhou.aliyuncs.com/home/swiper6.webp',
        'https://appu-common.oss-cn-hangzhou.aliyuncs.com/home/swiper7.webp'
    ]);
    const [swiperIndex, setSwiperIndex] = useState(0);

    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])

    useEffect(() => {
        switchIsShow(false);
    });

    const goBack=()=>{
        navigate(-1);  // 返回上一页
        // window.location.reload(); // 强制刷新页面
    }
    
    return <div className={s.about}>
        <div className={s.logo}>
            <img src="https://appu-common.oss-cn-hangzhou.aliyuncs.com/home/logo_1.webp" alt="" />
        </div>
        <div className={s.navigater}> <span className={s.shouye} onClick={goBack}>首页</span><span className={s.navigaterSpan}>{` > 关于工路快聘`}</span></div>

        <div className={s.title}>工路寄语</div>
        <div className={s.subTitle}>以AI加速蓝领就业，开启30秒匹配新时代</div>

        <div className={s.swiperWrap}>
            <div className={s.hr}></div>
            <div className={s.mySwiper}>
                <Swiper
                    modules={[Autoplay]}
                    direction={'vertical'}  // 设置垂直方向滚动
                    loop={true}
                    autoplay={{
                        delay: 5000,                 // 自动播放的间隔时间，单位毫秒
                        disableOnInteraction: false,  // 用户交互后是否继续自动播放
                    }}
                    speed={700} 
                    spaceBetween={0}
                    slidesPerView={1}
                    onSlideChange={(e) => {
                        console.log('slide change', e.realIndex);
                        setSwiperIndex(e.realIndex);
                    }}
                >
                    {
                        bannerList.map((item, index) =>
                            <SwiperSlide key={index}>
                                <img className={s.swiperImg} src={item} alt="" />
                            </SwiperSlide>
                        )
                    }
                </Swiper>
            </div>

            <div className={s.swiperPagenation}>
                <div className={[s.pagenationItem, swiperIndex === 0 ? s.pagenationItemActive : ''].join(' ')}></div>
                <div className={[s.pagenationItem, swiperIndex === 1 ? s.pagenationItemActive : ''].join(' ')}></div>
                <div className={[s.pagenationItem, swiperIndex === 2 ? s.pagenationItemActive : ''].join(' ')}></div>
                <div className={[s.pagenationItem, swiperIndex === 3 ? s.pagenationItemActive : ''].join(' ')}></div>
            </div>
        </div>

        <div className={[s.title, s.title2].join(' ')}>发展历程</div>
        <div className={s.subTitle}>工路快聘的成长之路</div>


        <img className={s.developHistory} src="https://appu-common.oss-cn-hangzhou.aliyuncs.com/home/develop_history.webp" alt="" />

        <div className={s.hr2}></div>

        <NewFooter />
    </div>
})
