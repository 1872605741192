/*
 * @Author: 孙林 1164700321@qq.com
 * @Date: 2024-08-09 10:36:36
 * @LastEditors: 孙林
 * @LastEditTime: 2024-08-15 13:55:32
 * @Description: 
 */
import DGuideModal from "@src/components/DGuideModal";
import Logo from "@src/components/Logo";
import { checkOpen } from "@src/utils/checkOpen";
import { handleDownload, ossDownload } from "@src/utils/download";
import { isWechat, isAndroid } from "@src/utils/env";
import { useEffect, useState } from "react";
import Block from "../Block";
import s from './index.module.scss';

export default function Screen1() {
    const [isDesktop, setIsDesktop] = useState(false);
    const [isShowModal, setIsShowModal] = useState(false);
    function detectDesktop() {
        if (document.documentElement.clientWidth > 768) {
            setIsDesktop(true);
        } else {
            setIsDesktop(false);
        }
    }

    const handleCloseModal = () => setIsShowModal(false);

    const handleAndroidApp = async () => {
        if (isWechat && isAndroid) {
            checkOpen(() => {
                setIsShowModal(true);
            });
        } else {
            checkOpen(() => {
                isAndroid && ossDownload();
            });
        }
        handleDownload();
    }

    const handleIosApp = async () => {
        handleDownload();
    }

    useEffect(() => {
        document.addEventListener("DOMContentLoaded", detectDesktop, false);
        document.addEventListener("resize", detectDesktop, false);
        window.addEventListener("load", detectDesktop, false);

        return () => {
            document.removeEventListener("DOMContentLoaded", detectDesktop);
            document.removeEventListener("resize", detectDesktop);
            window.removeEventListener("load", detectDesktop);
        }
    }, []);
    return <Block>
        <div className={s.bodyFour} id='bodyFour'>

            <div className={s.title} id='fourTitle'>金融服务助力蓝领行业发展   </div>
            <div className={s.subTitle} id='fourSubTitle'>日薪垫资服务赋能业务发展，保险服务降低风险并加强用户权益保障</div>
            <img className={s.fourImg} id='fourImg' src="https://appu-common.oss-cn-hangzhou.aliyuncs.com/home/screen4_img1.webp" alt="" />
        </div>
        <DGuideModal isShow={isShowModal} onClose={handleCloseModal} />
    </Block>
}
