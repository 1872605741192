/*
 * @Author: 孙林 1164700321@qq.com
 * @Date: 2024-08-20 11:06:05
 * @LastEditors: 孙林
 * @LastEditTime: 2024-08-23 11:24:52
 * @Description: 
 */
import DGuideModal from "@src/components/DGuideModal";
import Logo from "@src/components/Logo";
import { useEffect, useState } from "react";
import Block from "../Block";
import s from './index.module.scss';

export default function Screen1() {
    return <Block>
        <div className={s.screen1}>
            <div className={s.logoWrap}>
                <img src="https://appu-common.oss-cn-hangzhou.aliyuncs.com/home/phone_screen1_2.webp" alt="" />
            </div>
            <img className={s.screenImg3} src="https://appu-common.oss-cn-hangzhou.aliyuncs.com/home/replace1.webp" alt="" />
            <img className={s.screenImg4} src="https://appu-common.oss-cn-hangzhou.aliyuncs.com/home/phone_screen1_4.webp" alt="" />
        </div>

        <div className={s.screen2}>
            <div className={s.screenTitle}>我们致力解决的问题</div>
            <div className={s.screensubTitle}>求职难、招人难，低效匹配增加双方成本</div>
            <div className={s.screen2Content}>
                <div className={s.contentWrap}>
                    <p className={s.line1}>蓝领求职难</p>
                    <div className={s.line2}>
                        <img src="https://appu-common.oss-cn-hangzhou.aliyuncs.com/home/phone_screen2_3.webp" alt="" />
                        <span>打招呼无人理  应答等待漫长</span>
                    </div>
                    <div className={s.line3}>
                        <img src="https://appu-common.oss-cn-hangzhou.aliyuncs.com/home/phone_screen2_3.webp" alt="" />
                        <span>求职信息繁多  难以抉择</span>
                    </div>
                </div>
            </div>
        </div>

        <div className={s.screen3}>
            <div className={s.screenTitle}>产品介绍</div>
            <div className={s.screensubTitle}>视频匹配加速对接，高效连接求职者与经纪人</div>
            <div className={s.screen3Content}>
                <div className={s.line1}>
                    <img src="https://appu-common.oss-cn-hangzhou.aliyuncs.com/home/phone_screen3_3.webp" alt="" />
                    <span>视频开聊 + AI智能简历</span>
                </div>
                <div className={s.line2}>在线匹配求职，直接与人事/HR开聊；</div>
                <div className={s.line2}>享受打车般的求职体验，每一次沟通都在优化简历</div>
            </div>
            <img className={s.screenImg1} src="https://appu-common.oss-cn-hangzhou.aliyuncs.com/home/phone_screen3_2.webp" alt="" />
            <img className={s.screenImg4} src="https://appu-common.oss-cn-hangzhou.aliyuncs.com/home/phone_screen3_4.webp" alt="" />
        </div>

        <div className={s.screen6}>
            <div className={s.screenTitle}>金融服务助力蓝领行业发展</div>
            <div className={s.screensubTitle}>日薪垫资服务赋能业务发展</div>
            <div className={s.screensubTitle}>保险服务降低风险并加强用户权益保障</div>
            <img src="https://appu-common.oss-cn-hangzhou.aliyuncs.com/home/phone_screen5_3.webp" alt="" />
        </div>

        <div className={s.screen4}>
            <div className={s.screenTitle}>工路寄语</div>
            <div className={s.screensubTitle}>以AI加速蓝领就业，开启30秒匹配新时代</div>
            <img className={s.screenImg1} src="https://appu-common.oss-cn-hangzhou.aliyuncs.com/home/phone_screen4_1.webp" alt="" />
            <div className={s.content1}>
                <div>中国有<span>4.5</span>亿蓝领</div>
                <div className={s.divHr}>他们每找一次工作得到响应平均要</div>
                <div>花费超过<span>72</span>个小时</div>
                <div>这不仅让他们的就业选择机会减少, </div>
                <div>而且要在谋生的路上跨越更多荆棘</div>
            </div>
            <img className={s.screenImg2} src="https://appu-common.oss-cn-hangzhou.aliyuncs.com/home/replace3.webp" alt="" />
            <div className={s.content2}>
                <p>与白领找工作不同</p>
                <p>蓝领找工作没有简历</p>
                <p>他们讲着超出<span>100</span>种以上的方言，</p>
                <p>提出自己的期许</p>
                <p>可能是想去一次工厂，</p>
                <p>也可能仅仅是早上帮人搬一次洗衣机</p>
                <p>不管他们是在城市的街巷、制造业的工厂，</p>
                <p>还是广袤的乡村</p>
                <p>他们，都需要有人倾听和牵引</p>
                <p className={s.content2Title}>工路快聘</p>
                <p>在全国范围超过<span>300</span>个市县，</p>
                <p>从三亚到漠河，从上海到喀什</p>
                <p>接入10万职业经纪人</p>
                <p><span>24</span>小时响应每一个蓝领求职者的需求</p>
                <p>就像打车一样快</p>
            </div>
            <img className={s.screenImg3} src="https://appu-common.oss-cn-hangzhou.aliyuncs.com/home/replace4.webp" alt="" />
            <div className={s.content3}>
                <p className={s.content3p1}>工路快聘</p>
                <p>利用自己生成式<span>AI</span></p>
                <p>为每一位求职者生成自己的简历</p>
                <p>为求职者更精准的匹配经纪人和岗位</p>
                <p className={s.content3p2}>现在</p>
                <p>只需要在工路快聘APP</p>
                <p>点一下匹配</p>
                <p><span>30</span>秒！</p>
                <p>一位经纪人就会像网约车司机一样</p>
                <p>飞奔而来</p>
                <p>带上他，一起去敲开幸福的门</p>
            </div>
            <img className={s.screenImg3} src="https://appu-common.oss-cn-hangzhou.aliyuncs.com/home/replace5.webp" alt="" />

            <div className={s.content4}>
                <p>几亿蓝领不再用站在路边</p>
                <p>自己像被雨淋湿的孙悟空</p>
                <p>一声声地呼喊：“师傅！”</p>
                <p>却等不到一辆出租停在他们的身边</p>
                <p className={s.content4P1}>工路快聘</p>
                <p>已经为每天匹配<span>50</span>万次长期和零时工作的需求</p>
                <p>做好了准备</p>
            </div>
        </div>

        <div className={s.screen5}>
            <div className={s.screenTitle}>发展历程</div>
            <div className={s.screensubTitle}>工路快聘的成长之路</div>
            <img className={s.img1} src="https://appu-common.oss-cn-hangzhou.aliyuncs.com/home/phone_screen5_1.webp" alt="" />
            <img  src="https://appu-common.oss-cn-hangzhou.aliyuncs.com/home/phone_screen5_2.webp" alt="" />
        </div>
        

        <div className={s.footer}>
            <div className={s.codeWrapper}>
                <div className={s.codeItem}>
                    <img src="https://appu-common.oss-cn-hangzhou.aliyuncs.com/home/footer_twoCode.webp" alt="" />
                    <p>下载工路快聘APP</p>
                </div>
                <div className={s.codeItem}>
                    <img src="https://appu-common.oss-cn-hangzhou.aliyuncs.com/home/footer_miniCode.webp" alt="" />
                    <p>打开微信扫一扫</p>
                </div>
            </div>

            <div className={s.footerLine1}>
                <span>营业执照</span>
                <span>人力资源许可证</span>
            </div>
            
            <p className={s.customP}>成都阿朴机遇科技 版权所有</p>
            <p className={s.customP}>Copyright © 2022 apujiyu.com All Rights Reserved   </p>
            <p className={s.customP}> 蜀ICP备2022013735号-1</p>
        </div>
    </Block>
}
