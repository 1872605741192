let hidden;
let visibilityChange;

function getSupportedProperty(): void {
    if (typeof document === 'undefined') return;

    if (typeof document.hidden !== 'undefined') {
        // Opera 12.10 and Firefox 18 and later support
        hidden = 'hidden';
        visibilityChange = 'visibilitychange';
        //@ts-ignore
    } else if (typeof document.msHidden !== 'undefined') {
        hidden = 'msHidden';
        visibilityChange = 'msvisibilitychange';
        //@ts-ignore
    } else if (typeof document.webkitHidden !== 'undefined') {
        hidden = 'webkitHidden';
        visibilityChange = 'webkitvisibilitychange';
    }
}

getSupportedProperty();

/**
 * 判断页面是否隐藏（进入后台）
 */
function isPageHidden(): boolean {
    if (typeof hidden === 'undefined') return false;
    return document[hidden] as boolean;
}

export function checkOpen(failure: () => void): void {
    const timer = setTimeout(() => {
        const pageHidden = isPageHidden();
        if (!pageHidden) {
            failure();
        }
    }, 2000);

    if (typeof visibilityChange !== 'undefined') {
        document.addEventListener(visibilityChange, () => {
            clearTimeout(timer);
        });
    } else {
        window.addEventListener('pagehide', () => {
            clearTimeout(timer);
        });
    }
}
