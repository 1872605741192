/*
 * @Author: 孙林 1164700321@qq.com
 * @Date: 2024-08-09 10:36:36
 * @LastEditors: 孙林
 * @LastEditTime: 2024-08-28 16:38:49
 * @Description: 
 */

import { useNavigate } from 'react-router-dom';
import s from './index.module.scss';

export default function NewFooter() {
    const navigate = useNavigate();

    const goAbout=()=>{
        navigate('/about')
        window.scrollTo(0, 0);  // 跳转后将滚动位置重置到页面顶部
    }

    return <>
        <div className={s.aboutWrapper}>
            <div className={s.aboutLeft}>
                <div className={s.aboutTitle}>关于我们</div>
                <div className={s.aboutSubTitle}>初心不改，岁月成诗</div>
            </div>
            <div className={s.aboutRight} onClick={goAbout}>
                <div>查看更多</div>
                <img src="https://appu-common.oss-cn-hangzhou.aliyuncs.com/home/jiantou1.webp" alt="" />
            </div>
        </div>
    </>

}
