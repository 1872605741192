const ua = navigator.userAgent || '';
export const __DEV__ = process.env.NODE_ENV === 'development';

export const isWechat = /micromessenger\/([\d.]+)/i.test(ua);

export const isAndroid = /android/i.test(ua);
export const isIphone = /iphone|ipod/i.test(ua);
export const is_mobile = isAndroid || isIphone;
export const isIpad =
    navigator.userAgent.match(/(iPad)/) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
