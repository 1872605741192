import { createContext, ReactNode, useContext, useState } from 'react';
import s from './index.module.scss';
interface LoadingContextType {
    isShow: boolean;
    switchIsShow: (boolean) => void
}

export const LoadingContext = createContext<LoadingContextType>(null!);

export function LoadingProvider({ children }: { children: ReactNode }) {
    const [isShow, setIsShow] = useState<boolean>(true);
    const switchIsShow = val => setIsShow(val);

    const uniloadingCtx = { isShow, switchIsShow };

    return <LoadingContext.Provider value={uniloadingCtx}>{children}</LoadingContext.Provider>;
}

export function useLoading() {
    return useContext(LoadingContext);
}

const loadingText = '机遇马上就到 ...';

export default function UniLoading() {
    const { isShow } = useLoading();
    return <div className={s.loading} style={isShow ? {} : {
        opacity: 0,
        zIndex: -1,
    }}>
        <div className={s.loadingBg}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={'108px'}
                height={'108px'}
                viewBox="0 0 144 144"
                xmlSpace="preserve"
            >
                <path
                    style={{
                        stroke: "#fff",
                        strokeWidth: '2px',
                        strokeLinecap: "butt",
                        strokeLinejoin: "miter",
                        strokeMiterlimit: 4,
                        fill: "#fff",
                        fillOpacity: 0,
                        fillRule: "nonzero",
                        opacity: 1,
                    }}
                    vectorEffect="non-scaling-stroke"
                    transform="translate(73.28 66.12)"
                    d="m12.38-34.727-1.93 46.463-41.961 5.467-14.47-13.023 7.074 30.547 60.611-15.595-1.608-23.473 25.885-20.9v-6.753z"
                    strokeLinecap="round"
                    fill="none"
                />
                <path
                    style={{
                        stroke: "#fff",
                        strokeWidth: '2px',
                        strokeLinecap: "butt",
                        strokeLinejoin: "miter",
                        strokeMiterlimit: 4,
                        fill: "#fff",
                        fillOpacity: 0,
                        fillRule: "nonzero",
                        opacity: 1,
                    }}
                    vectorEffect="non-scaling-stroke"
                    transform="translate(100.37 40.4)"
                    d="M-5.064-3.698v7.396L5.064-1.125v-1.287z"
                    strokeLinecap="round"
                    fill="none"
                />
                <path
                    style={{
                        stroke: "#fff",
                        strokeWidth: '2px',
                        strokeLinecap: "butt",
                        strokeLinejoin: "miter",
                        strokeMiterlimit: 4,
                        fill: "#fff",
                        fillOpacity: 0,
                        fillRule: "nonzero",
                        opacity: 1,
                    }}
                    vectorEffect="non-scaling-stroke"
                    transform="translate(77.94 34.28)"
                    d="m-4.662-2.081 9.324 5.466v-6.77z"
                    strokeLinecap="round"
                    fill="none"
                />
                <path
                    style={{
                        stroke: "#fff",
                        strokeWidth: '2px',
                        strokeLinecap: "butt",
                        strokeLinejoin: "miter",
                        strokeMiterlimit: 4,
                        fill: "#fff",
                        fillOpacity: 0,
                        fillRule: "nonzero",
                        opacity: 1,
                    }}
                    vectorEffect="non-scaling-stroke"
                    transform="translate(75.61 43.37)"
                    d="M-6.35-3.939 6.35 3.94v-6.913z"
                    strokeLinecap="round"
                    fill="none"
                />
                <path
                    style={{
                        stroke: "#fff",
                        strokeWidth: '2px',
                        strokeLinecap: "butt",
                        strokeLinejoin: "miter",
                        strokeMiterlimit: 4,
                        fill: "#fff",
                        fillOpacity: 0,
                        fillRule: "nonzero",
                        opacity: 1,
                    }}
                    vectorEffect="non-scaling-stroke"
                    transform="translate(33.86 108.71)"
                    d="M.16-5.547-8.52 5.547 8.52-2.17z"
                    strokeLinecap="round"
                    fill="none"
                />
                <path
                    style={{
                        stroke: "#fff",
                        strokeWidth: '2px',
                        strokeLinecap: "butt",
                        strokeLinejoin: "miter",
                        strokeMiterlimit: 4,
                        fill: "#fff",
                        fillOpacity: 0,
                        fillRule: "nonzero",
                        opacity: 1,
                    }}
                    vectorEffect="non-scaling-stroke"
                    transform="translate(101.26 94.94)"
                    d="m-10.37 2.25 20.74 4.824L-4.904-7.074z"
                    strokeLinecap="round"
                    fill="none"
                />
                <path
                    style={{
                        stroke: "#fff",
                        strokeWidth: '2px',
                        strokeLinecap: "butt",
                        strokeLinejoin: "miter",
                        strokeMiterlimit: 4,
                        fill: "none",
                        fillRule: "nonzero",
                        opacity: 1,
                    }}
                    vectorEffect="non-scaling-stroke"
                    transform="matrix(1.15 0 0 1.15 72 72)"
                    d="M0-59.377c32.776 0 59.377 26.601 59.377 59.377S32.776 59.377 0 59.377-59.377 32.776-59.377 0-32.776-59.377 0-59.377z"
                    strokeLinecap="round"
                />
            </svg>
        </div>
        <div className={s.text}>
            {loadingText}
        </div>
    </div>
}
