/*
 * @Author: 孙林 1164700321@qq.com
 * @Date: 2024-08-09 10:36:36
 * @LastEditors: 孙林
 * @LastEditTime: 2024-08-15 13:45:10
 * @Description: 
 */
import DGuideModal from "@src/components/DGuideModal";
import Logo from "@src/components/Logo";
import { checkOpen } from "@src/utils/checkOpen";
import { handleDownload, ossDownload } from "@src/utils/download";
import { isWechat, isAndroid } from "@src/utils/env";
import { useEffect, useState } from "react";
import Block from "../Block";
import s from './index.module.scss';

export default function Screen1() {
    const [isDesktop, setIsDesktop] = useState(false);
    const [isShowModal, setIsShowModal] = useState(false);

    const [isShow, setIsShow] = useState(false);
    function detectDesktop() {
        if (document.documentElement.clientWidth > 768) {
            setIsDesktop(true);
        } else {
            setIsDesktop(false);
        }
    }

    const handleCloseModal = () => setIsShowModal(false);

    const handleAndroidApp = async () => {
        if (isWechat && isAndroid) {
            checkOpen(() => {
                setIsShowModal(true);
            });
        } else {
            checkOpen(() => {
                isAndroid && ossDownload();
            });
        }
        handleDownload();
    }

    const handleIosApp = async () => {
        handleDownload();
    }

    useEffect(() => {
        document.addEventListener("DOMContentLoaded", detectDesktop, false);
        document.addEventListener("resize", detectDesktop, false);
        window.addEventListener("load", detectDesktop, false);

        return () => {
            document.removeEventListener("DOMContentLoaded", detectDesktop);
            document.removeEventListener("resize", detectDesktop);
            window.removeEventListener("load", detectDesktop);
        }
    }, []);


    useEffect(() => {
        // const target = document.querySelector("#contentWrappper");
        // const ob = new IntersectionObserver(
        //     (entries) => {   // entries 监听元素的数组,可监听多个dom元素
        //         const entry = entries[0];
        //         console.log(entry);
        //         if (entry.isIntersecting) {  // 监听到了元素处于可视区域
        //             console.log("监听到了")
        //             setIsShow(true)
        //         } else {
        //             setIsShow(false)
        //         }
        //     },
        //     {
        //         root: null,
        //         threshold: 0,
        //     }
        // )
        // if (!target) return
        // ob.observe(target);
    }, [])

    return <Block>
        <div className={s.bodyTwo} id='bodyTwo'>
            <div className={s.titles} id='titles'>
                <div id='twoTitle'>我们致力解决的问题</div>
                <div id='twoSubtitle'>求职难、招人难，低效匹配增加双方成本</div>
            </div>
            {/* <div id='kongbai'></div>  isShow ? s.contentWrappperShow : s.contentWrappperHide, */}
            <div className={[ s.contentWrappper].join(' ')} id='contentWrappper'>
                <div className={s.contentWrappper_left} id='leftDom'>
                    <img src="https://appu-common.oss-cn-hangzhou.aliyuncs.com/home/screen2_left.webp" alt="" />
                    <div className={s.leftContent}>
                        <div className={s.contentTitle}>蓝领求职难</div>
                        <div className={s.infos}>
                            <div className={s.infoItem}>
                                <img src="https://appu-common.oss-cn-hangzhou.aliyuncs.com/home/screen2_jiantou.webp" alt="" />
                                <div>打招呼无人  应答等待漫长</div>
                            </div>
                            <div className={s.infoItem}>
                                <img src="https://appu-common.oss-cn-hangzhou.aliyuncs.com/home/screen2_jiantou.webp" alt="" />
                                <div>求职信息繁多  难以抉择</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={s.contentWrappper_right}>
                    <img src="https://appu-common.oss-cn-hangzhou.aliyuncs.com/home/screen2_right.webp" alt="" />
                    <div className={s.leftContent}>
                        <div className={s.contentTitle}>蓝领招聘难</div>
                        <div className={s.infos}>
                            <div className={s.infoItem}>
                                <img src="https://appu-common.oss-cn-hangzhou.aliyuncs.com/home/screen2_jiantou.webp" alt="" />
                                <div>招人成本高  流量越来越贵</div>
                            </div>
                            <div className={s.infoItem}>
                                <img src="https://appu-common.oss-cn-hangzhou.aliyuncs.com/home/screen2_jiantou.webp" alt="" />
                                <div>业务转化率低  效果难保障</div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <DGuideModal isShow={isShowModal} onClose={handleCloseModal} />
    </Block>
}
