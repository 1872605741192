/*
 * @Author: 孙林 1164700321@qq.com
 * @Date: 2024-08-09 10:36:36
 * @LastEditors: 孙林
 * @LastEditTime: 2024-08-28 18:07:16
 * @Description: 
 */
import DGuideModal from "@src/components/DGuideModal";
import Logo from "@src/components/Logo";
import { checkOpen } from "@src/utils/checkOpen";
import { handleDownload, ossDownload } from "@src/utils/download";
import { isWechat, isAndroid } from "@src/utils/env";
import { useEffect, useState, useRef } from "react";
import Block from "../Block";
import s from './index.module.scss';

declare var OpenInstall: any;
var buttonDom: any = null;

export default function Screen1() {
    const [isDesktop, setIsDesktop] = useState(false);
    const [isShowModal, setIsShowModal] = useState(false);
    function detectDesktop() {
        if (document.documentElement.clientWidth > 768) {
            setIsDesktop(true);
        } else {
            setIsDesktop(false);
        }
    }

    const handleCloseModal = () => setIsShowModal(false);

    const handleAndroidApp = async () => {
        if (isWechat && isAndroid) {
            checkOpen(() => {
                setIsShowModal(true);
            });
        } else {
            checkOpen(() => {
                isAndroid && ossDownload();
            });
        }
        handleDownload();
    }

    const handleIosApp = async () => {
        handleDownload();
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        document.addEventListener("DOMContentLoaded", detectDesktop, false);
        document.addEventListener("resize", detectDesktop, false);
        window.addEventListener("load", detectDesktop, false);

        return () => {
            document.removeEventListener("DOMContentLoaded", detectDesktop);
            document.removeEventListener("resize", detectDesktop);
            window.removeEventListener("load", detectDesktop);
        }
    }, []);

    useEffect(() => {
        const loadOpenInstall = async () => {
            const script = document.createElement('script');
            script.src = 'https://res.openinstall.com/openinstall-wumenn.js';
            script.async = true;
            script.addEventListener('load', () => {
                var data = OpenInstall.parseUrlParams();
                new OpenInstall({
                    appKey: "wumenn",
                    fastInstall: false,
                    onready: function () {
                        console.log("sdk 初始化完成")
                        this.schemeWakeup();
                        buttonDom = document.getElementById("downloadButton");
                        // console.log("buttonDom", buttonDom)
                        let m = this;
                        buttonDom.onclick = function () {
                            console.log("buttonDom click")
                            m.wakeupOrInstall();
                            return false;
                        }
                    }
                }, data);
            }, false);
            document.body.appendChild(script);
        };
        loadOpenInstall();
    }, [])

    return <Block>
        <div className={s.bodyOne} id='bodyOne'>
            <div className={s.logo}>
                <img src="https://appu-common.oss-cn-hangzhou.aliyuncs.com/home/logo_1.webp" alt="" />
            </div>
            <div className={s.contentWrappper}>
                <div className={s.contentWrappper_left}>
                    <img src="https://appu-common.oss-cn-hangzhou.aliyuncs.com/home/screen1_left.webp" alt="" />
                    {/* <div className={s.dowmloadBtn} id='downloadButton'>立即体验</div> */}
                    <a className={s.dowmloadBtn}  href="#footer">立即体验</a>
                </div>
                <img className={s.contentWrappper_right} src="https://appu-common.oss-cn-hangzhou.aliyuncs.com/home/screen1_right1.webp" alt="" />
            </div>
        </div>
        <DGuideModal isShow={isShowModal} onClose={handleCloseModal} />
    </Block>
}
