import CallApp from 'callapp-lib';
import { forward, parseQuery, buildQuery } from './url';

const urlParams: Record<string, any> = parseQuery();
const paramString = buildQuery(urlParams);

export const ApuScheme = 'apu';
export const ApuHost = 'apu.tech';
export const OpenAppFlag = 'isUniverse';
export const ApuPkgName = 'com.apu.tech.apu_app';
export const appstoreUrl = 'https://apps.apple.com/cn/app/%E9%98%BF%E6%9C%B4%E6%9C%BA%E9%81%87/id1596631510';

// apu://apu.tech
const callLib = new CallApp({
    scheme: {
        protocol: ApuScheme,
        host: ApuHost
    },
    intent: {
        package: ApuPkgName,
        scheme: ApuScheme,
    },
    universal: {
        host: 'links.apujiyu.com',
    },
    appstore: appstoreUrl,
    timeout: 2500,
    fallback: 'https://links.apujiyu.com/download?' + paramString,
});

export default function openApp(path, param?) {
    const openParam = Object.assign({}, param, {
        [OpenAppFlag]: true,
    });
    const { sfu } = urlParams;
    callLib.open({
        path,
        param: openParam,
        callback: () => {
            forward('/download', {
                sfu,
            });
        },
    });
}
