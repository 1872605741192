/*
 * @Author: 孙林 1164700321@qq.com
 * @Date: 2024-08-08 14:44:24
 * @LastEditors: 孙林
 * @LastEditTime: 2024-08-28 14:38:47
 * @Description: 
 */
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { __DEV__ } from "./env";

export default function VConsole() {
    const [params] = useSearchParams();
    const show = params.get('__debug');
    useEffect(() => {
        if (!__DEV__ && !show) {
            return;
        }
        let script = document.createElement("script");
        script.type = "text/javascript";
        script.src = 'https://unpkg.com/vconsole@latest/dist/vconsole.min.js';
        document.body.appendChild(script);
        script.onload = () => {
            // @ts-ignore
            var vConsole = new window.VConsole();
        }
    })
    return null;
}
