/*
 * @Author: 孙林 1164700321@qq.com
 * @Date: 2024-08-09 10:36:36
 * @LastEditors: 孙林
 * @LastEditTime: 2024-08-28 18:02:49
 * @Description: 
 */

import { checkOpen } from "@src/utils/checkOpen";
import { handleDownload, ossDownload } from "@src/utils/download";
import { isWechat, isAndroid } from "@src/utils/env";
import { useState } from "react";

import s from './index.module.scss';

export default function NewFooter() {
    const [isShowModal, setIsShowModal] = useState(false);
    const handleCloseModal = () => setIsShowModal(false);
    const handleAndroidApp = async () => {
        if (isWechat && isAndroid) {
            checkOpen(() => {
                setIsShowModal(true);
            });
        } else {
            checkOpen(() => {
                isAndroid && ossDownload();
            });
        }
        handleDownload();
    }

    const handleIosApp = async () => {
        handleDownload();
    }

    const handleWxPublic = () => {
        window.open('https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=Mzg5NDgyNTYxNw==&scene=124#wechat_redirect')
    }


    return <>
        <div className={s.footer} id='footer'>
            <div className={s.wrapper}>
                <div className={s.leftWrapper}>
                    <img className={s.footerLogo} src="https://appu-common.oss-cn-hangzhou.aliyuncs.com/home/footer_logo.webp"></img>
                    <div className={s.tel}>联系电话：(028) 86027268</div>
                    <div className={s.addEmail}>电子邮箱：contact@apujiyu.com</div>
                    <div className={s.addEmail}>联系地址：四川省成都市高新区天府软件园B区3栋303号</div>
                </div>
                <div className={s.centerWrapper}>
                    <div className={s.centerItem}>
                        <img className={s.centerItemImg} src="https://appu-common.oss-cn-hangzhou.aliyuncs.com/home/footer_twoCode.webp"></img>
                        <div>下载工路快聘APP</div>
                    </div>
                    <div className={s.centerItem}>
                        <img className={s.centerItemImg} src="https://appu-common.oss-cn-hangzhou.aliyuncs.com/home/footer_miniCode.webp"></img>
                        <div>打开微信扫一扫</div>
                    </div>
                </div>

                <div className={s.rightWrapper}>
                    <div className={s.rightItem} onClick={handleWxPublic}>
                        <img className={s.rightItemImg} src="https://appu-common.oss-cn-hangzhou.aliyuncs.com/home/footer_wx.webp"></img>
                        <div >微信公众号</div>
                    </div>
                    <div className={s.rightItem}>
                        <img className={s.rightItemImg} src="https://appu-common.oss-cn-hangzhou.aliyuncs.com/home/footer_xhs.webp"></img>
                        <div>小红书</div>
                    </div>
                    <div className={s.rightItem}>
                        <img className={s.rightItemImg} src="https://appu-common.oss-cn-hangzhou.aliyuncs.com/home/footer_dy.webp"></img>
                        <div>抖音</div>
                    </div>
                </div>
            </div>
            <div className={s.hr}></div>
            <div className={s.certificates}>
                <a href="https://ulink.apujiyu.com/cert">营业执照</a>
                <div>人力资源许可证</div>
            </div>
            <div className={s.detailInfo}>成都阿朴机遇科技 版权所有    Copyright © 2022 apujiyu.com All Rights Reserved     蜀ICP备2022013735号-1</div>
        </div>
    </>

}
